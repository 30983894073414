import { RefObject, useEffect } from 'react';

import type { Sliver } from '../types/sliver';

function performForEachSliverVideo(
  refSlivers: RefObject<{ [key: number]: RefObject<HTMLVideoElement> }>,
  slivers: Sliver[],
  action: (sliverVideo: HTMLVideoElement) => void,
) {
  slivers.forEach(sliver => {
    if (refSlivers.current) {
      const sliverVideo = refSlivers.current[sliver.id]?.current;
      if (sliverVideo) {
        action(sliverVideo);
      }
    }
  });
}

export default function useSyncedVideoPlayback(
  refMain: RefObject<HTMLVideoElement>,
  refSlivers: RefObject<{ [key: number]: RefObject<HTMLVideoElement> }>,
  slivers: Sliver[],
) {
  useEffect(() => {
    const mainVideo = refMain.current;
    if (!mainVideo) {
      return;
    }
    mainVideo.addEventListener('play', () =>
      performForEachSliverVideo(refSlivers, slivers, sliverVideo =>
        sliverVideo.play(),
      ),
    );
    mainVideo.addEventListener('pause', () =>
      performForEachSliverVideo(refSlivers, slivers, sliverVideo =>
        sliverVideo.pause(),
      ),
    );
    mainVideo.addEventListener('timeupdate', () =>
      performForEachSliverVideo(refSlivers, slivers, sliverVideo => {
        sliverVideo.currentTime = mainVideo.currentTime;
      }),
    );
    mainVideo.addEventListener('ratechange', () =>
      performForEachSliverVideo(refSlivers, slivers, sliverVideo => {
        sliverVideo.playbackRate = mainVideo.playbackRate;
      }),
    );
  }, [refMain, refSlivers, slivers]);
}
