import { createRef, RefObject, useEffect, useRef, useState } from 'react';

import { useElementSize } from 'usehooks-ts';

import sliversData from './data/slivers.json';
import useSyncedVideoPlayback from './hooks/useSyncedVideoPlayback';
import cn from './lib/cn';
import type { Sliver } from './types/sliver';

const VIDEO_SRC = '/assets/video.mp4';
const VIDEO_ASPECT_RATIO = 16 / 9;

const SLIVER_SCALE = 1.25;
const SLIVER_WIDTH = 25;

const slivers: Sliver[] = sliversData;

function App() {
  // Create refs to access the HTML video elements directly.
  const refMain = useRef<HTMLVideoElement>(null);
  const refSlivers = useRef<{ [key: number]: RefObject<HTMLVideoElement> }>({});
  slivers.forEach(sliver => {
    if (!refSlivers.current[sliver.id]) {
      refSlivers.current[sliver.id] = createRef<HTMLVideoElement>();
    }
  });

  // Sync the main video's playback with the sliver videos.
  useSyncedVideoPlayback(refMain, refSlivers, slivers);

  const [visibleSlivers, setVisibleSlivers] = useState<Sliver[]>([]);
  useEffect(() => {
    const mainVideo = refMain.current;
    if (!mainVideo) {
      return;
    }
    mainVideo.addEventListener('timeupdate', () => {
      // Set the visible slivers based on the current time.
      const visibleSlivers = slivers.filter(
        sliver =>
          sliver.start <= mainVideo.currentTime &&
          sliver.end >= mainVideo.currentTime,
      );
      setVisibleSlivers(visibleSlivers);
    });
  }, []);

  // Track the size of the main video's container. This allows us to
  // responsively size the sliver videos.
  const [squareRef, { width }] = useElementSize();
  // The useElementSize hook returns the height of the element as well, but it's
  // not reliable on the initial render, so we'll calculate the height
  // ourselves. This means we work with a fixed aspect ratio, which is fine for
  // this proof of concept.
  const height = width / VIDEO_ASPECT_RATIO;

  return (
    <div className="container mx-auto sm:p-4">
      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="relative shrink-0 grow-0 lg:basis-3/4" ref={squareRef}>
          <video
            ref={refMain}
            className="h-auto w-full drop-shadow-md sm:rounded-md"
            src={VIDEO_SRC}
            controls
          />
          {width &&
            visibleSlivers.map(sliver => {
              // This is a really hacky way to calculate where the guide line
              // should end up vertically. It's based on how the slivers are
              // shown on the right of the video on large screens. The guide
              // lines are not implemented on small screens.
              const sliverHeight =
                (height / width) * width * (SLIVER_WIDTH / 100) * SLIVER_SCALE;
              const lineTop =
                sliverHeight * sliver.id -
                // We want the line at around 20% from the top of the sliver.
                sliverHeight * 0.8 +
                // This is where it gets even hackier. The slivers are spaced
                // out by roughly 70px, so we'll just multiply that by the
                // sliver's ID, minus 1, to get the vertical offset.
                70 * (sliver.id - 1);

              return (
                <svg
                  key={sliver.id}
                  className="absolute left-0 top-0 stroke-white drop-shadow-md sm:rounded-md"
                  width={width}
                  height={height}
                  strokeWidth="2"
                  // Make sure the SVG doesn't block clicks on the video.
                  pointerEvents="none"
                >
                  <defs>
                    <mask id="mask">
                      <rect width="100%" height="100%" fill="white" />
                      <rect
                        x={width * (sliver.left / 100)}
                        y={height * (sliver.top / 100)}
                        width={width * (SLIVER_WIDTH / 100)}
                        height={(height / width) * width * (SLIVER_WIDTH / 100)}
                        rx="5"
                        ry="5"
                        fill="black"
                        stroke="none"
                      />
                    </mask>
                  </defs>
                  <rect
                    width="100%"
                    height="100%"
                    fill="rgba(0,0,0,0.6)"
                    stroke="none"
                    mask="url(#mask)"
                  />
                  <polyline
                    className="guide-line hidden lg:block"
                    points={[
                      [
                        width * (sliver.left / 100),
                        height * (sliver.top / 100) - height * 0.015,
                      ],
                      [
                        width * (sliver.left / 100) +
                          width * (SLIVER_WIDTH / 100),
                        height * (sliver.top / 100) - height * 0.015,
                      ],
                      [
                        width * (sliver.left / 100) +
                          width * (SLIVER_WIDTH / 100),
                        lineTop,
                      ],
                      [width, lineTop],
                    ]
                      .map(pair => pair.join(','))
                      .join(' ')}
                    fill="none"
                  />
                </svg>
              );
            })}
        </div>
        <div
          className={cn(
            'flex shrink-0 grow-0 lg:basis-1/4',
            'justify-center space-x-4 sm:justify-start',
            'lg:flex-col lg:space-x-0 lg:space-y-6',
          )}
        >
          {width &&
            slivers.map(sliver => (
              <div key={sliver.id} className="cursor-pointer">
                <div
                  className="relative overflow-hidden rounded-md drop-shadow-md"
                  style={{
                    width: width * (SLIVER_WIDTH / 100) * SLIVER_SCALE,
                    height:
                      (height / width) *
                      width *
                      (SLIVER_WIDTH / 100) *
                      SLIVER_SCALE,
                  }}
                >
                  <video
                    ref={refSlivers.current[sliver.id]}
                    className="absolute h-auto max-w-none"
                    style={{
                      width: width * SLIVER_SCALE,
                      top: -1 * height * (sliver.top / 100) * SLIVER_SCALE,
                      left: -1 * width * (sliver.left / 100) * SLIVER_SCALE,
                    }}
                    src={VIDEO_SRC}
                  />
                </div>
                <div className="mt-2 inline-block bg-black p-1 text-sm leading-none text-white lg:text-base xl:text-lg">
                  {sliver.cta}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default App;
